import React, { useState } from "react";
import "./Header.css"; // Custom CSS file for styling

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="header">
      <div className="container-fluid">
        <nav className="header-navbar">
          {/* Logo */}
          <div className="logo">
            <a href="/"><img className="h-8" src="../../../../DaylightLogo.png" alt="Growth graph" /></a>
          </div>
  
          {/* Navigation Links */}
          <div className={`nav-links ${isOpen ? "open" : ""}`}>
            {/* <a href="#">Marketplace</a>
            <a href="#">Testnet</a>
            <a href="#">Explorer</a>
            <a href="#">Blog</a>
            <a href="#">Community</a> */}
            <div className="nav-buttons">
              <a href="https://apps.apple.com/us/app/daylight-energy/id6450997694" target="_blank" className="btn btn-signup">
                Download App
              </a>
            </div>
          </div>
  
          {/* Buttons */}
          
  
          {/* Mobile Menu Toggle */}
          <button
            className="menu-toggle"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle navigation"
          >
            <span className={`bar ${isOpen ? "active" : ""}`}></span>
            <span className={`bar ${isOpen ? "active" : ""}`}></span>
            <span className={`bar ${isOpen ? "active" : ""}`}></span>
          </button>          
        </nav>
      </div>

      
    </header>
  );
};

export default Header;
